@import '../../assets/colors.less';

.calendar-agenda {
	display: block;
	width: 100%;
	padding: 24px 0;
	.react-calendar {
		width: 100%;
		border: 0;
	}
	.react-calendar__tile {
		&:hover {
			background: lighten(@primary, 45%);
		}
		&:active,
		&:focus {
			background: transparent;
		}
		& > abbr {
			margin: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
		}
	}

	.react-calendar__month-view__days__day {
		&:not(&.react-calendar__month-view__days__day--neighboringMonth) {
			color: @black80;
		}
	}
	.react-calendar__month-view__days__day--neighboringMonth {
		opacity: 0.3;
	}

	.react-calendar__tile--now {
		background: transparent;
		&:not(&.react-calendar__month-view__days__day--neighboringMonth) > abbr {
			background: transparent;
			color: @primary;
		}
	}
	.react-calendar__tile--active {
		background: transparent;
		display: flex;
		justify-content: center;
		align-items: center;

		&:not(&.react-calendar__month-view__days__day--neighboringMonth) > abbr {
			display: flex;
			justify-content: center;
			align-items: center;
			background: @primary;
			border-radius: 50%;
			color: @white;
			width: 32px;
			height: 32px;
		}
	}
	.marked-custom {
		position: relative;
		&.disabled {
			cursor: not-allowed !important;
			opacity: 0.3;
		}
		.dots-container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			transform: translateY(-10px);
		}
		.dots-marked {
			&:nth-child(1) {
				margin: 0;
			}
			margin-left: 2px;
			display: block;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: @black80;
		}
	}

	.list-agenda {
		max-height: 500px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 5px;
		}
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		&::-webkit-scrollbar-thumb {
			background: @primary;
		}
	}

	.item-group {
		position: relative;
		padding: 16px 16px 0;

		.line {
			position: absolute;
			//   top: 85px;
			top: 110px;
			bottom: 0;
			left: 36px;
			width: 2px;
			flex: 1;
			background-color: #d5dce1;
			z-index: 0;
		}
		.dateheader {
			background-color: @white;
			font-size: 22px;
		}
		.date {
			font-size: 1rem;
		}
	}

	.detail-card {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		margin-top: 20px;
		padding-bottom: 10px;
		border-radius: 12px;
		display: flex;
		.line-item {
			position: absolute;
			width: 2px;
			z-index: 1;
			top: 0;
			bottom: 0;
			left: 20px;
			flex: 1;
			background-color: @white;
		}
		.content-container {
			z-index: 2;
			flex: 1;
			margin-left: 14px;
		}
		.header-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				margin: 0;
				&:nth-child(1) {
					flex: 1;
				}
			}
			.time-n-more {
				display: flex;
				margin-left: 12px;
			}
		}
		.icon-container {
			z-index: 2;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			& > * {
				width: 65%;
				height: 65%;
			}
		}
		.chevron-container {
			cursor: pointer;
			margin-left: 15px;
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: rotate(90deg);
			transition: 0.3s;
			&.is-open {
				transform: rotate(-90deg);
				transition: 0.3s;
			}
		}
		.title-item {
			margin: 10px 0;
			font-weight: bold;
			font-size: 20px;
		}
		.ellip-separator {
			margin-left: 8px;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: @gray20;
			+ * {
				margin-left: 8px;
			}
		}
		.detail {
			display: flex;
			align-items: center;
		}
		.location-container {
			margin-top: 8px;
			.location {
				margin-left: 8px;
			}
		}
		.btn-action {
			margin-top: 12px;
		}
		.btn-telemedicine {
			margin-top: 12px;
			&.is-past {
				background-color: @disabled;
				border-width: 1px;
				border-color: @disabled;
				padding: 11px;
				border-radius: 12px;
				justify-content: center;
				align-items: center;
			}
			& > p {
				margin: 0;
			}
		}
		.text-white {
			color: @white;
		}
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;