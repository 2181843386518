.patient-detail-container {
	padding: 16px;
	background-color: #fff;
  
	.overview-tab {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  height: 100%;
  
	  .profile-section {
		display: flex;
		gap: 40px;
		width: 100%;
  
		.profile-info {
		  flex: 1;
		  display: flex;
		  flex-direction: column;
  
		  .info-columns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 40px;
			row-gap:10px;
  
			.info-column {
			  .field-label {
				font-size: 14px;
				color: #888;
				margin-bottom: 2px;
			  }
  
			  .field-value {
				font-size: 16px;
				color: #020202;
			  }
			}
		  }
		}
	  }
  
	  .contact-info {
		width: 100%;
		margin-top: 5px;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		gap: 4px;
  
		.field-label {
		  font-size: 14px;
		  color: #888;
		  margin-bottom: 2px;
		}
  
		.field-value {
		  font-size: 16px;
		  color: #080808;
		}
	  }
	}
  
	.history-tab {
	  display: flex;
	  flex-direction: column;
	  padding: 10px;
	  font-size: 16px;
	  height: 100%;
  
	  .history-item {
		padding: 10px;
		margin-bottom: 10px;
  
		.history-details {
		  display: grid;
		  grid-template-columns: 1fr 1fr; 
		  column-gap: 20px;
		  row-gap: 5px;
  
		  .history-column {
			.field-label {
			  font-size: 14px;
			  color: #888;
			  margin-bottom: 2px;
			}
  
			.field-value {
			  font-size: 16px;
			  color: #070707;
			}
		  }
		}
  
		hr {
		  margin: 5px 0;
		}
	  }
	}
  }
  
@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;