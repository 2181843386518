@import '~antd/dist/antd.less';
@import './colors.less';
@import './layout.less';
@import './style.less';

* {
	font-family: 'League Spartan', sans-serif;
	font-weight: 400;
}
img {
	max-width: 100%;
}
html {
	scrollbar-gutter: stable;
}

.btn-text-bold {
	span {
		font-weight: 600;
	}
}

// .ant-picker-footer {
// 	 display: none !important;
// }

#hero {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-bottom: -50px;
	position: relative;
	z-index: 1;
	overflow: hidden;

	&:before {
		content: '';
		width: 2032.35px;
		height: 2032.35px;
		left: -923.13px;
		top: -187.99px;
		opacity: 0.25;
		position: absolute;
		border-radius: 50%;
		border: 320px solid #e9e9e9;
	}

	&:after {
		content: '';
		position: absolute;
		width: 1305.98px;
		height: 1305.98px;
		left: 772.09px;
		top: 99.91px;

		opacity: 0.25;
		border: 320px solid #e9e9e9;

		border-radius: 50%;
		z-index: -1;
	}

	.hero-grid {
		text-align: center;
	}
}

#features {
	padding: 180px 0 80px 0;
	background-image: url('./rectangle.svg');
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 1;
	overflow: hidden;

	.content {
		margin: 52px auto;
		max-width: 1100px;
	}
	h3.ant-typography {
		max-width: 600px;
		margin: auto;
	}

	.card-hug .ant-card-body {
		padding: 4px 32px 30px;
	}

	img.card-icon {
		width: 145px;
		margin: auto;
		padding: 15px;
	}
	&:after {
		content: '';
		background-image: url('./rectangle2.svg');
		width: 100%;
		height: 100%;
		position: absolute;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		left: 0;
		right: 0;
		bottom: -200px;
		z-index: -1;
	}
}

#perks-and-benefit {
	padding: 70px 0 70px 0;
	z-index: 1;

	img.card-icon {
		width: 88px;
		margin: auto;
	}

	.content {
		margin: 78px auto;
	}

	.card-hug {
		.ant-card-meta-title {
			font-size: 24px;
			font-weight: 600;
		}
		.ant-card-meta-description {
			font-weight: 400;
			font-size: 18px;
		}
	}

	// @media (min-width: 992px) {
	//   padding: 150px 0 100px 0;
	// }
}

#more {
	background-color: #f7f8fa;
	padding-top: 72px;
	padding-bottom: 72px;
	z-index: 1;

	.ant-card-body {
		padding: 48px;
	}

	h4.ant-typography {
		margin-top: 24px;
	}
}

#promo-deals {
	// background-color: @gray10;
	padding-top: 70px;
	padding-bottom: 70px;
	z-index: 1;

	.ant-card-body {
		padding: 48px;
	}

	h4.ant-typography {
		margin-top: 24px;
	}

	.swiper {
		.swiper-slide-active {
			img.img-banner-voucher {
				transform: scale(1.1);
				height: 100%;
			}
		}

		img.img-banner-voucher {
			border-radius: 20px;
			box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
			width: 100%;
			height: 200px;

			cursor: pointer;
		}

		.swiper-wrapper {
			align-items: center;
		}
		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			background: transparent;
			border: 2px solid #686868;
		}
		.swiper-pagination-bullet.swiper-pagination-bullet-active {
			background-color: @primary-color;
			border: none;
		}

		.swiper-horizontal > .swiper-pagination-bullets,
		.swiper-pagination-bullets.swiper-pagination-horizontal {
			bottom: 0px !important;
		}

		@media screen and (min-width: 992px) {
			img.img-banner-voucher {
				height: 306px;
			}

			.swiper-slide-active {
				img.img-banner-voucher {
					transform: scale(1.1);
					height: 240px;
				}
			}
		}
		@media screen and (min-width: 1500px) {
			img.img-banner-voucher {
				height: 306px;
			}

			.swiper-slide-active {
				img.img-banner-voucher {
					transform: scale(1.1);
					height: 306px;
				}
			}
		}
	}
}

#articles {
	// background-color: @gray10;
	// padding-top: 48px;
	padding-bottom: 72px;
	z-index: 1;

	img {
		display: block;
		height: 500px;
		object-fit: cover;
		border-radius: 10px;
		width: 100%;
	}
}

#download {
	background-color: @primary-color;
	padding-top: 72px;
	padding-bottom: 72px;
	min-height: 430px;
	overflow: hidden;
	z-index: 1;

	.ant-card-body {
		padding: 48px;
	}

	h4.ant-typography {
		margin-top: 24px;
	}
}

#choose-medeasy {
	background-color: @primary-color;
	padding-top: 72px;
	padding-bottom: 72px;
	overflow: hidden;
	z-index: 1;

	&:before {
		content: '';
		height: 688px;
		width: 688px;
		position: absolute;
		left: -114px;
		top: -424px;
		// background: #c51d5b;
		border-radius: 50%;
		border: 240px solid #c61b5b;
		z-index: -1;
	}

	&:after {
		content: '';
		position: absolute;
		width: 622px;
		height: 622px;
		right: -40px;
		top: calc(50% - 622px / 2 + 293px);
		border-radius: 50%;
		border: 240px solid #c61b5b;
		z-index: -1;
	}
}

#join-as-doctor {
	background-color: @primary-color;
	padding: 80px 0;
	position: relative;
	overflow: hidden;
	z-index: 1;

	@media screen and (min-width: 992px) {
		padding: 120px 0;
	}

	.ant-typography {
		color: @white;
	}

	h4.ant-typography {
		font-size: 48px !important;
		margin-bottom: 0;
	}

	&:before {
		content: '';
		height: 241px;
		width: 241px;
		position: absolute;
		left: -200px;
		top: -25px;
		background: #c51d5b;
		opacity: 0.5;
		border-radius: 32px;
		transform: rotate(28.5deg);
	}

	&:after {
		content: '';
		position: absolute;
		width: 672px;
		height: 672px;
		left: 1700.66px;
		top: -410px;
		background: #c51d5b;
		opacity: 0.75;
		border-radius: 32px;
		transform: rotate(30deg);
		z-index: -1;
	}
}

#features-app {
	background-color: @gray10;
	padding-top: 80px;
	padding-bottom: 80px;
	overflow: hidden;
	z-index: 10 !important;

	.card-service {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 20px;
		overflow: hidden;
		box-shadow:
			0px 4px 6px -1px rgba(0, 0, 0, 0.1),
			0px 2px 4px -1px rgba(0, 0, 0, 0.06);
		.ant-card-body {
			padding: 20px;

			img {
				margin-bottom: 15px;
			}
			.ant-card-meta-title {
				font-weight: 500;
				font-size: 24px;
			}
			.ant-card-meta-description {
				font-weight: 400;
				font-size: 14px;
				color: @gray100;
			}
		}
	}
}
.single-content {
	padding: 56px 20px;

	p {
		margin: 0;
		text-align: justify;
	}
}
.card-grid {
	flex-direction: column-reverse;
	text-align: center;
}
.logo-large {
	width: 80px;
}

.wrapper-login {
	padding: 20px 20px 0px;
	background-color: #fff;
	border-radius: 20px;
}

@media screen and (min-width: 768px) {
	#join-as-doctor {
		&:after {
			right: -470px;
		}
	}

	.card-grid {
		text-align: left;
		flex-wrap: nowrap;
		flex-direction: unset;
	}

	.single-content {
		padding: 56px;
	}
}
@media screen and (min-width: 992px) {
	#hero {
		.hero-grid {
			flex-wrap: nowrap;
			text-align: left;
		}
		.hero-text {
			flex: auto;
		}
		.hero-image {
			flex: 600px;
		}
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;