@import '../../../assets/colors.less';

.pregnancy-date {
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: auto;
		margin-right: 10px;
		order: -1;
		align-items: center;
	}

	.ant-picker .ant-picker-input {
		input {
			padding-top: 6px !important;
			text-align: end !important;
		}
	}
}

.circle-text,
.circle-img {
	position: relative;
	z-index: 99;

	.circle {
		width: 138px;
		height: 138px;
		background-color: #fddae7;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		border: none;
	}
}
.circle-img {
	img {
		width: 128px;
		height: 128px;
	}
}

.pregnancy-results {
	.ant-card-body {
		padding: 16px !important;
	}
}

.card-item-calculator {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 150px;
	border-radius: 12px;
	background-color: #f8fafb;
}

.select-wrapper .ant-select .ant-select-selector {
	border-radius: 14px !important;
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;