@import '../../../assets/colors.less';

.add-schedule-screen {
	background: @white;
	border-radius: 12px;
	padding: 0 24px;
	.header-section {
		padding: 22px 0;
		border-bottom: 1px solid @gray10;
		box-shadow: 0 2px rgba(0, 0, 0, 0.05);
		h4 {
			flex: 1;
			font-weight: 600;
			font-size: 24px;
			margin: 0;
		}
		.nav-top {
			display: flex;
			margin: 20px 0;
			.item-tab {
				padding: 0 15px;
				flex: 1;
				display: flex;
				flex-flow: column;
				.item-content {
					cursor: pointer;
					flex: 1;
					padding: 10px;
					display: flex;
					flex-flow: column;
					align-items: center;
					border-radius: 12px;
					border-width: 1px;
					border-style: solid;
					p {
						&.inactive {
							color: @black50;
						}
						margin: 8px 0 0;
						font-size: 14px;
						line-height: 1.25em;
						text-align: center;
					}
					.icon-container {
						width: 40px;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 12px;
						& > * {
							width: 24px;
							height: 24px;
						}
					}
				}
			}
		}
		.for-doctor {
			margin: 15px 0 0;
			display: flex;
			align-items: center;
			.toggle {
				margin: 0 0 0 15px;
			}
			.content-container {
				flex: 1;
				.title-plc {
					display: flex;
					align-items: center;
					h5 {
						margin: 0;
					}
					.icon-information {
						margin: 0 0 0 8px;
						width: 1rem;
					}
				}
				p {
					margin: 0;
					font-size: 14px;
					line-height: 1.25em;
					color: @charcoal200;
				}
			}
		}
	}
	.form-plc {
		padding: 12px 0;
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;