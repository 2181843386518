@import '../../../../assets/colors.less';

.form-personal-event {
	.blocking-toggle {
		display: flex;
		align-items: center;
		.toggle {
			margin: 0 0 0 15px;
		}
		.content-container {
			flex: 1;
			.title-plc {
				display: flex;
				align-items: center;
				h5 {
					margin: 0;
				}
				.icon-information {
					margin: 0 0 0 8px;
					width: 1rem;
				}
			}
			p {
				margin: 0;
				font-size: 14px;
				line-height: 1.25em;
				color: @charcoal200;
			}
		}
	}
	.date-picker,
	.time-picker {
		font-size: 1rem;
	}
	.date-picker {
		width: 75%;
	}
	.border-box-bottom {
		padding-bottom: 22px;
		border-bottom: 1px solid @gray10;
		box-shadow: 0 2px rgba(0, 0, 0, 0.05);
	}
	.description {
		padding-top: 22px;
	}
}

.for-doctor {
	margin: 15px 0 0;
	display: flex;
	align-items: center;
	.toggle {
		margin: 0 0 0 15px;
	}
	.content-container {
		flex: 1;
		.title-plc {
			display: flex;
			align-items: center;
			h5 {
				margin: 0;
			}
			.icon-information {
				margin: 0 0 0 8px;
				width: 1rem;
			}
		}
		p {
			margin: 0;
			font-size: 14px;
			line-height: 1.25em;
			color: @charcoal200;
		}
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;