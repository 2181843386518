@import '../../../assets/colors.less';

.modal {
	.icon {
		height: 64px;
		width: 64px;
		background-color: @gray10;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
	}
}

.btn-next {
	width: 26px;
	background-color: @gray10;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;

	&:disabled {
		background-color: @gray10;
	}
	&.left {
		.chevron-left {
			transform: rotate(180deg);
		}
	}
}

.carousel-item input[type='radio']:checked + .carousel-body {
	background-color: @primary;
}

.carousel-item input[type='radio']:checked + .carousel-body p {
	color: #fff !important;
}

.carousel-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 6px;
	border-radius: 6px;
	background-color: #fff;
	box-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.3),
		0px 1px 2px rgba(0, 0, 0, 0.06);

	p {
		text-transform: uppercase;
		margin-bottom: 0;
		color: @charcoal200;
		font-size: 12px;
	}

	p.date {
		font-size: 18px;
		font-weight: 500;
	}
}

.time-wrapper {
	margin-right: 12px;
	margin-bottom: 12px;

	.time {
		height: 32px;
		width: 76px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		font-size: 14px;
		font-weight: 600;
		color: @charcoal200;
		border: 1px solid #93a1b0;
	}
}

.time-wrapper input[type='radio']:checked + .time {
	background-color: @primary;
	color: #fff !important;
	border: none !important;
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;