@import '../../../assets/colors.less';

.item-group {
	position: relative;
	padding: 16px 16px 0;

	.line {
		position: absolute;
		//   top: 85px;
		top: 110px;
		bottom: 0;
		left: 36px;
		width: 2px;
		flex: 1;
		background-color: #d5dce1;
		z-index: 0;
	}
	.dateheader {
		background-color: @white;
		font-size: 22px;
	}
	.date {
		font-size: 1rem;
	}
}

.detail-card {
	cursor: pointer;
	overflow: hidden;
	position: relative;
	margin-top: 20px;
	padding-bottom: 10px;
	border-radius: 12px;
	display: flex;
	.line-item {
		position: absolute;
		width: 2px;
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 20px;
		flex: 1;
		background-color: @white;
	}
	.content-container {
		z-index: 2;
		flex: 1;
		margin-left: 14px;
	}
	.header-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin: 0;
			&:nth-child(1) {
				flex: 1;
			}
		}
		.time-n-more {
			display: flex;
			margin-left: 12px;
		}
	}
	.icon-container {
		z-index: 2;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		& > * {
			width: 65%;
			height: 65%;
		}
	}
	.chevron-container {
		cursor: pointer;
		margin-left: 15px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: rotate(90deg);
		transition: 0.3s;
		&.is-open {
			transform: rotate(-90deg);
			transition: 0.3s;
		}
	}
	.title-item {
		margin: 10px 0;
		font-weight: bold;
		font-size: 20px;
	}
	.ellip-separator {
		margin-left: 8px;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background: @gray20;
		+ * {
			margin-left: 8px;
		}
	}
	.detail {
		display: flex;
		align-items: center;
	}
	.location-container {
		margin-top: 8px;
		.location {
			margin-left: 8px;
		}
	}
	.btn-action {
		margin-top: 12px;
	}
	.btn-telemedicine {
		margin-top: 12px;
		&.is-past {
			background-color: @disabled;
			border-width: 1px;
			border-color: @disabled;
			padding: 11px;
			border-radius: 12px;
			justify-content: center;
			align-items: center;
		}
		& > p {
			margin: 0;
		}
	}
	.text-white {
		color: @white;
	}
}

.schedule-screen {
	background: @white;
	border-radius: 12px;

	.header-schedule {
		padding: 22px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid @gray10;
		box-shadow: 0 2px rgba(0, 0, 0, 0.05);
		h4 {
			flex: 1;
			font-weight: 600;
			font-size: 24px;
			margin: 0;
		}
		.btn-add-event {
			cursor: pointer;
			margin-left: 20px;
			border: 1px solid @primary;
			padding: 8px 12px;
			border-radius: 10px;
			& > p {
				color: @primary;
				margin: 0;
				font-size: 14px;
				font-weight: 600;
			}
		}
		.dots-three {
			cursor: pointer;
			margin-left: 20px;
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			span {
				position: relative;
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background: @gray100;
				&::after,
				&::before {
					display: block;
					content: '';
					position: absolute;
					width: 3px;
					height: 3px;
					border-radius: 50%;
					background: @gray100;
				}
				&::before {
					transform: translateY(calc(-100% - 2px));
				}
				&::after {
					transform: translateY(calc(100% + 2px));
				}
			}
		}
	}
}

.reschedule-wrapper {
	padding: 12px;
	border-radius: 12px;
	border: '1px solid #F1F3F5';
	box-shadow:
		0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
	margin-bottom: 20px;

	.icon-wrapper {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: @primary;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			-webkit-text-stroke: 3 white;
		}
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;