@import '../../../../assets/colors.less';

.reward {
	background: linear-gradient(93.13deg, #e41462 -15%, #ffda74 99.39%);
	padding: 20px;
	border-radius: 16px;
	position: relative;
	overflow: hidden;

	.rectangle {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		height: 100%;
	}

	.dot {
		position: absolute;
		background-color: #fff;
		height: 22px;
		width: 22px;
		border-radius: 50%;
	}

	.dot.right {
		left: 100%;
		transform: translateX(-50%);
	}

	.dot.left {
		left: 0;
		transform: translateX(-50%);
	}
}

.circle {
	width: 548px;
	height: 548px;
	border: 6px solid @gray20;
	border-radius: 50%;
	position: absolute;

	&.circle-1 {
		width: 548px;
		height: 548px;
		top: -385px;
		left: -385px;
	}

	&.circle-2 {
		width: 532px;
		height: 532px;
		top: -410px;
		right: -330px;
	}
	&.circle-3 {
		width: 720px;
		height: 720px;
		top: -440px;
		right: -610px;
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;