.check-box-style {
	.ant-checkbox-inner {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 20px;
		height: 20px;
		direction: ltr;
		background-color: #fff;
		border: 1px solid #d9d9d9;
		border-radius: 0px;
		border-collapse: separate;
		transition: all 0.3s;
	}
	.ant-checkbox-checked .ant-checkbox-inner::after {
		position: absolute;
		display: table;
		border: 2px solid white;
		border-top-color: white;
		border-top-style: solid;
		border-top-width: 2px;
		border-left-color: white;
		border-left-style: solid;
		border-left-width: 2px;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg) scale(1) translate(-50%, -50%);
		opacity: 1;
		transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
		content: ' ';
		margin-left: 1px;
	}
}

.events {
	margin: 0;
	padding: 0;
	list-style: none;
}
.events .ant-badge-status {
	width: 100%;
	overflow: hidden;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.notes-month {
	font-size: 28px;
	text-align: center;
}
.notes-month section {
	font-size: 28px;
}

.select-input {
	.ant-select-selector {
		border-radius: 12px !important;
	}
}

.date-input {
	.ant-picker-input {
		input {
			padding-top: 6px !important;
			text-align: end !important;
		}
	}
	.ant-picker-suffix {
		order: -1;
	}
}

.ant-btn {
	border-radius: 12px !important;
	// border-color: black !important;
}

.ant-btn-lg {
	height: 42px !important;
	width: 184px !important;
	font-weight: 900 !important;
}

.mens-date-custom {
	.ant-picker-cell {
		height: 65px;
	}

	.ant-picker-content thead {
		height: 65px;
	}

	// .ant-picker-calendar-date-today {
	//   background-color: white !important;
	//   border: none !important;
	//   .ant-picker-calendar-date-value {
	//     border: none !important;
	//     color: black;
	//   }
	// }

	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
		color: gray;
		background-color: white;
	}

	.ant-picker-cell .ant-picker-cell-inner {
		position: relative;
		z-index: 2;
		display: inline-block;
		min-width: 52px;
		height: 52px;
		line-height: 52px;
		border-radius: 50%;
		transition:
			background 0.3s,
			border 0.3s;
		// border: 1px solid black;
		// background-color: green;
		// color: red;
	}

	.ant-picker-cell-in-view.ant-picker-cell-today
		.ant-picker-cell-inner::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		border: 2px solid gray;
		border-radius: 50%;
		content: '';
	}
}

// .period-date {
//   .ant-picker-cell .ant-picker-cell-inner {
//     position: relative;
//     z-index: 2;
//     display: inline-block;
//     min-width: 52px;
//     height: 52px;
//     line-height: 52px;
//     border-radius: 50%;
//     transition: background 0.3s, border 0.3s;
//     // border: 1px solid black;
//     // background-color: green;
//     color: white;
//   }
// }

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;