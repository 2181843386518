@import '../../../assets/colors.less';

.event-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	//   margin-top: 7px;
	margin-bottom: 14px;
	gap: 8px;
	// min-height: 300px;
	cursor: pointer;

	width: 100%;
	height: auto;

	background: #ffffff;
	border: 1px solid #dcdcdc;
	border-radius: 16px;
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;