@import '../../assets/colors.less';

.toggle-btn {
	position: relative;
	display: inline-block;
	width: 2rem;
	height: 0.85rem;
	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .slider {
			background-color: @white;
			&:before {
				background-color: @primary;
				transform: translateX(1rem);
			}
		}
		&:focus + .slider {
			box-shadow: 0 0 1px @primary;
		}
	}
	.slider {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: @gray10;
		border-radius: 34px;
		box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.3);
		transition: 0.4s;
		display: flex;
		align-items: center;
		&.disabled {
			cursor: default;
		}
		&::before {
			position: absolute;
			content: '';
			width: 1rem;
			height: 1rem;
			top: 0;
			left: 0px;
			bottom: 0px;
			margin: auto;
			background-color: @gray20;
			box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			transition: 0.4s;
		}
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;