.ant-layout-header {
	padding: unset;
}
.ant-modal-content {
	border-radius: 12px;
	/* background: red; */
}
.no-margin {
	margin: 0px !important;
}
.modal-padding {
	padding: 20px;
}
.ant-scrolling-effect {
	width: unset !important;
}
.toggle-menu .ant-btn-default {
	margin-right: -20px;
}
#header {
	background: @white;
	border-bottom: 1px solid @gray20;
	position: sticky;
	top: 0;
	z-index: 1000;

	.container-lg {
		display: grid;
		grid-template-columns: minmax(10px, 1fr) auto auto;
		column-gap: 20px;
	}

	.container {
		display: grid;
		grid-template-columns: minmax(10px, 1fr) auto auto;
		column-gap: 20px;
	}

	.nav-menu {
		display: none;

		a {
			color: @gray70;
			padding: 0 15px;
			display: inline-block;

			&.active {
				color: @primary-color;
			}
		}
	}

	.right-header {
		display: none;
	}
}
.mobile-menu {
	display: block;

	a {
		display: block;
		padding: 5px 0;
		color: @gray70;

		&.active {
			color: @primary-color;
		}
	}
}
.container-lg {
	max-width: 1920px;
	margin: auto;
	padding: 0 24px;

	@media (min-width: 1024px) {
		padding: 0 60px;
	}
}
.container {
	padding: 0px 20px;
	max-width: 1440px;
	margin: auto;
}
.container-sm {
	max-width: 1200px;
}
.container-xs {
	max-width: 1000px;
}
h1.ant-typography {
	color: @primary-color;
	font-size: 50px;
}
h2.ant-typography {
	font-size: 32px;
	font-weight: 400;
	color: @black80;
}
h3.ant-typography {
	font-size: 48px;
	color: @primary-color;
}
h4.ant-typography {
	font-size: 32px;
	color: @primary-color;
}
.ant-btn.ant-btn-default {
	color: @primary-color;
	border: none;
}
.ant-typography.white-heading {
	color: @white;
}
.center {
	text-align: center;
}
.card-hug {
	border-radius: 32px;
	height: 100%;
}
.card-shadow {
	box-shadow:
		0px 2px 27px -1px rgba(0, 0, 0, 0.29),
		0px 2px 4px -1px rgba(0, 0, 0, 0.1);
}
.card-shadow-md {
	box-shadow:
		0px 4px 6px -1px rgba(0, 0, 0, 0.1),
		0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.ant-card-meta-title {
	color: @primary-color;
	font-size: 32px;
	font-weight: 600;
}
.ant-card-meta-description {
	font-size: 16px;
	line-height: 19px;
}
.subtitle {
	font-size: 20px;
}
.tag-primary {
	color: @white;
	background-color: @primary-color;
	border-radius: 24px;
	padding: 12px 24px;
	font-weight: 600;
}
.ant-drawer-content-wrapper {
	max-width: 100%;
}
.menu-mobile-drawer {
	.additional-menu-mobile {
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
	}
}
#footer {
	margin-top: 33px;
	// border-top: 1px solid @gray20;
	padding: 60px 0;
	text-align: center;

	.logo-footer span {
		margin-left: 10px;
		color: @primary-color;
		font-weight: 600;
		font-size: 24px;
	}

	.links {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			a {
				color: @text-color-secondary;
			}

			a:hover {
				color: @primary-color;
			}
		}
	}

	.footer-download {
		justify-content: center;
	}

	.copyright {
		margin-top: 80px;
	}
}
#section-title {
	background-color: @primary-color;
	padding: 48px 0;

	.ant-typography {
		color: @white;
	}

	h1.ant-typography {
		font-size: 40px;
		line-height: 48px;
		margin: 6px 0;
	}
}
.webapp-content-layout {
	max-width: 1920px;
	margin: auto;
	background-color: @gray10;
	display: flex;
	padding: 24px;

	@media (min-width: 1024px) {
		padding: 28px 28px;
	}
}
.webapp-content {
	flex: 1;
	min-width: 0;

	@media (min-width: 1024px) {
		padding: 0 24px;
	}
}

@media screen and (min-width: 768px) {
	.menu-mobile-drawer {
		.ant-drawer-footer {
			display: none;
		}
	}

	.container {
		padding: 0px 50px;
	}

	#header {
		.right-header {
			display: flex;
		}
	}
	h1.ant-typography {
		font-size: 72px;
	}

	#footer {
		text-align: left;

		.footer-download {
			justify-content: flex-start;
		}
	}
}

@media screen and (min-width: 1210px) {
	#header {
		.toggle-menu {
			display: none;
		}
		.container-lg {
			grid-template-columns: auto minmax(10px, 1fr) auto;
		}
		.container {
			grid-template-columns: auto minmax(10px, 1fr) auto;
		}
		.nav-menu {
			display: block;
		}
	}
}

.modal-h-full {
	.ant-modal {
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding-top: 100px;
		padding-bottom: 100px;

		.ant-modal-content {
			flex: 1;
			min-height: 0;
			display: flex;
			flex-direction: column;

			.ant-modal-body {
				flex: 1;
				min-height: 0;
				display: flex;
				flex-direction: column;
			}
		}
	}
}

.custom-scroll-div {
	overflow: auto;
	&::-webkit-scrollbar {
		width: 4px;
		height: 8px;
	}
	&::-webkit-scrollbar-thumb {
		width: 4px;
		height: 8px;
		background: @gray20;
		border-radius: 32px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: @gray30;
	}
	&::-webkit-scrollbar-thumb:active {
		background: @gray60;
	}
}
