@import '../../../assets/colors.less';

.app-button {
	.primary {
		background-color: @primary;
		padding: 12px;
		border-radius: 12px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.primaryDisabled {
		background-color: gray;
		padding: 12px;
		border-radius: 12px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.primaryText {
		text-align: center;
		color: @white;
		font-size: 16px;
		// font-family: "LeagueSpartan-SemiBold";
	}
	.secondary {
		background-color: @white;
		border-color: @primary;
		border-width: 1px;
		border-radius: 12px;
		padding: 12px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.secondaryDisabled {
		background-color: @disabled;
		padding: 12px;
		border-radius: 12px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.secondaryText {
		text-align: center;
		color: @primary;
		font-size: 16px;
		// font-family: "LeagueSpartan-SemiBold";
	}
	.outlined {
		background-color: @white;
		border-width: 1px;
		padding: 11px;
		border-radius: 12px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.outlinedDisabled {
		background-color: @disabled;
		border-width: 1px;
		border-color: @disabled;
		padding: 11px;
		border-radius: 12px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.outlinedText {
		text-align: center;
		color: @primary;
		font-size: 16px;
		// font-family: "LeagueSpartan-SemiBold";
	}
	.opacity {
		text-align: center;
		font-size: 16px;
		// font-family: "LeagueSpartan-SemiBold";
	}
	.default {
		background-color: @ash300;
		padding: 12px;
		border-radius: 12px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.defaultDisabled {
		background-color: @disabled;
		padding: 12px;
		border-radius: 12px;
	}
}

@primary-color: #D81F64;@border-radius-base: 6px;@layout-header-height: 80px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #FF4A50;@menu-highlight-color: #FFFFFF;@layout-body-background: #FFF;@layout-header-background: #FFF;@font-size-base: 18px;@padding-md: 18px;@text-color: #1D2B36;@text-color-secondary: #748494;